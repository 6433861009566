<template>
  <div class="c-page-description">
    <h1 class="c-page-description__title c-page-description__title_bold">
      About Sparrow
    </h1>
    <h2 class="c-page-description__desc">
      your digital assets partner
    </h2>
    <p class="c-page-description__content c-page-description__content-who">
      Sparrow offers innovative digital asset products and solutions. Headquartered in Singapore, Sparrow has established
      an trading platform that aims to be secure, compliant and intuitive for individual subscribers. In addition, we work
      with financial institutions and family offices in designing bespoke digital asset solutions to achieve
      customer-centric growth objectives, while not compromising on financial reporting and compliance requirements.
      <br /><br />
      Backed by known investors in the digital assets space, we are also a partner of financial institutions and wealth
      managers.
      <br /><br />
      Established in 2018, Sparrow (through its entity Sparrow Tech Private Limited) has obtained the Major Payment
      Institution (MPI) licence from the Monetary Authority of Singapore (MAS) to provide Digital Payment Token (DPT)
      services in Singapore under the Payment Services Act (PSA). We have a team of deeply experienced individuals who
      focus on Fintech, digital asset products, infrastructure building, compliance, and the user experience.
      <br /><br />
    </p>
    <div class="c-page-description__we-box">
      <div class="c-page-description__we-box-content" v-for="(dt, index) in boxList" :key="index"
        @click="clickImage(dt.link)">
        <img class="c-page-description__we-box-content-img"
          :src="pressedIndex == index ? dt.imgPressed : hoveredIndex == index ? dt.imgHovered : dt.img"
          @mouseover="hoverImage(index)" @mouseleave="hoverImage(null)" @mousedown="pressImage(index)"
          @mouseup="pressImage(null)" />
        <p class="c-page-description__we-box-content-text">{{ dt.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoveredIndex: null,
      pressedIndex: null,
      boxList: [
        {
          img: require('@/assets/images/about-brand.svg'),
          imgHovered: require('@/assets/images/about-brand-hover.svg'),
          imgPressed: require('@/assets/images/about-brand-pressed.svg'),
          text: 'Brand',
          link: 'https://sparrowexchange.com/about/why-sparrow'
        },
        {
          img: require('@/assets/images/about-expertise.svg'),
          imgHovered: require('@/assets/images/about-expertise-hover.svg'),
          imgPressed: require('@/assets/images/about-expertise-pressed.svg'),
          text: 'Expertise',
          link: 'https://sparrowexchange.com/people-at-sparrow/team'
        },
        {
          img: require('@/assets/images/about-security.svg'),
          imgHovered: require('@/assets/images/about-security-hover.svg'),
          imgPressed: require('@/assets/images/about-security-pressed.svg'),
          text: 'Security',
          link: 'https://sparrowexchange.com/legal/user-protection-guidance'
        },
        {
          img: require('@/assets/images/about-regulation.svg'),
          imgHovered: require('@/assets/images/about-regulation-hover.svg'),
          imgPressed: require('@/assets/images/about-regulation-pressed.svg'),
          text: 'Regulation',
          link: 'https://sparrowexchange.com/legal/regulation'
        },
        {
          img: require('@/assets/images/about-solution.svg'),
          imgHovered: require('@/assets/images/about-solution-hover.svg'),
          imgPressed: require('@/assets/images/about-solution-pressed.svg'),
          text: 'Solution',
          link: 'https://sparrowexchange.com/institutional/wealth'
        },
        {
          img: require('@/assets/images/about-assurance.svg'),
          imgHovered: require('@/assets/images/about-assurance-hover.svg'),
          imgPressed: require('@/assets/images/about-assurance-pressed.svg'),
          text: 'Assurance',
          link: 'https://sparrowexchange.com/about/why-sparrow'
        },
        {
          img: require('@/assets/images/about-service.svg'),
          imgHovered: require('@/assets/images/about-service-hover.svg'),
          imgPressed: require('@/assets/images/about-service-pressed.svg'),
          text: 'Service',
          link: 'https://sparrowexchange.com/institutional/wealth'
        },
      ]
    }
  },
  methods: {
    hoverImage(idx) {
      this.hoveredIndex = idx
    },
    pressImage(idx) {
      this.pressedIndex = idx
    },
    clickImage(link) {
      this.openLink(link)
    },
  }
}
</script>
